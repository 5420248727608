<template>
    <div class="blocked-accounts">
        <h1>Blocked Accounts</h1>
        <template v-if="blockedUsers.length">
            <people-card
                v-for="user in blockedUsers"
                :key="user.id"
                :user="user"
                class="item"
                :container-style="{ width: '100%' }"
                @toggled-follow="(is_following) => user.is_following = is_following"
                @unblock-user="unblockUser"
            />
        </template>
        <div v-else class="no-blocked-user">
            <img src="@assets/img/icons/blocked-users.svg" alt="Blocked Accounts">
            <h1>No blocked users</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlockedAccounts",
    components: {
        PeopleCard: () => import(/* webpackChunkName: "people-card" */ "@c/molecules/people-card")
    },
    data() {
        return {
            blockedUsers: []
        };
    },
    created() {
        this.getBlockedUsers();
    },
    methods: {
        getBlockedUsers() {
            axiosV2.get("/block-users").then(({ data }) => {
                this.blockedUsers = data;
            })
        },
        unblockUser(userId) {
            axiosV2.post(`/block-users/${userId}`).then(({ data }) => {
                const userIndex = this.blockedUsers.findIndex(user => user.id === userId);
                this.blockedUsers[userIndex].is_blocked = data.is_blocked;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.blocked-accounts {
    @media(max-width: $lg) {
        padding-top: 50px;
    }

    h1 {
        margin-bottom: 40px;
    }

    .no-blocked-user {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;

        img {
            margin-bottom: 30px;
        }

        h1 {
            margin-bottom: 10px;
            font-weight: 700;
        }
    }
}
</style>
